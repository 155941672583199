import React from 'react';
import {
  Stack, Typography, CircularProgress, List, ListItem, Tooltip,
} from '@mui/joy';
import { LandCoverClassesResponse } from 'api/openapi/generated/RawApi';

interface LandCoverListProps {
  landCover: LandCoverClassesResponse | undefined;
  isLandCoverLoading: boolean;
}

const LandCoverList: React.FC<LandCoverListProps> = ({ landCover, isLandCoverLoading }) => (
  <Stack
    sx={{
      position: 'absolute',
      bottom: 50,
      left: 10,
      zIndex: 20,
      padding: 1,
      borderRadius: '4px',
      boxShadow: 1,
      backgroundColor: 'Background',
    }}
  >
    <Typography
      textAlign="center"
      sx={{
        borderBottom: '1px solid lightGrey',
      }}
    >
      Land cover classes
    </Typography>
    {isLandCoverLoading && <CircularProgress size="sm" />}
    <List sx={{ width: 'fit-content' }}>
      {landCover?.classes.map((cls) => (
        <ListItem
          sx={{
            justifyContent: 'space-between',
            gap: 5,
            '&:hover': {
              backgroundColor: 'lightGrey',
              cursor: 'pointer',
            },
          }}
        >
          {/* Land cover label */}
          <Typography>{cls.label}</Typography>

          {/* Land cover color */}
          <Typography
            sx={{
              backgroundColor: cls.color,
              borderRadius: '5px',
              width: '20px',
              height: '20px',
            }}
          />
        </ListItem>
      ))}
    </List>
  </Stack>
);

export default LandCoverList;
