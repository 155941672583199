import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography, Stack,
  useColorScheme,
} from '@mui/joy';
import { useParams } from 'react-router-dom';
import {
  PlotGroundTruth, PlotProperties, PlotsResponse, LandCoverClassesResponse,
  LandCoverClassDetail,
} from 'api/openapi/generated/RawApi';
import KanopApi from 'api/openapi/KanopApi';
import { toast } from 'react-toastify';
import LandCoverSelector from '../Inputs/LandCoverSelector';
import TextInput from '../Inputs/TextInput';

interface PopupFormProps {
  setOpen: (open: boolean) => void;
  map: mapboxgl.Map | null,
  draw: MapboxDraw | null;
  currentFeature: PlotGroundTruth[] | null;
  properties: PlotProperties | undefined;
  setFeature: (feature: PlotGroundTruth | undefined) => void;
  setPlotData: (plotData: PlotsResponse) => void;
  collectionLocked?: boolean;
  removeLayerAndSource: (map: mapboxgl.Map) => void;
  refetchLandCover: () => void;
  setproperties: (properties: PlotProperties) => void;
  landCover: LandCoverClassesResponse | undefined,
}
const PopupForm = ({
  setOpen,
  currentFeature,
  removeLayerAndSource,
  setFeature,
  map,
  refetchLandCover,
  properties,
  setproperties,
  draw,
  setPlotData,
  landCover,
  collectionLocked,
}: PopupFormProps) => {
  const { collectionId } = useParams<{ collectionId: string | undefined }>();
  const [formState, setFormState] = useState<PlotProperties>({
    plotReference: properties?.plotReference || '',
    observationDate: properties?.observationDate || '',
    treeHeightMean: properties?.treeHeightMean,
    canopyHeightMean: properties?.canopyHeightMean,
    canopyCover: properties?.canopyCover,
    agbValue: properties?.agbValue || null,
    landCoverClassId: properties?.landCoverClassId,
    soilOrganicCarbon30cm: properties?.soilOrganicCarbon30cm,
    comments: properties?.comments,
    collectionId,
  });

  const { mode, systemMode } = useColorScheme();
  const darkMode = mode === 'dark' || systemMode === 'dark';

  const [isError, setIsError] = useState(false);
  const [ColorError] = useState<boolean>(false);
  const [landCoverOptions, setLandCoverOptions] = useState<LandCoverClassDetail[]>([]);

  const [newLandCover, setNewLandCover] = useState(false);
  const [inputValues, setInputValues] = useState<string|undefined>();
  const [selectedColor, setSelectedColor] = useState<LandCoverClassDetail | null>(null);

  const handleChange = (e: { target: { name: string; value: string; }; }) => {
    const { name, value } = e.target;
    if (value === '') {
      setFormState((prev) => ({ ...prev, [name]: null }));
      return;
    }
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  // Fetch plots data from the server
  const {
    data: plot,
    refetch,
  } = KanopApi.useGetPlotsForCollectionsCollectionsCollectionIdPlotsGetQuery({
    collectionId: collectionId as string,
  });

  const [uploadPlot,
    {
      isLoading: isUploading,
    },
  ] = KanopApi.useUploadGtForPlotsCollectionsCollectionIdPlotsPostMutation();
  const [upDatePlot] = KanopApi.useUpdateGtForPlotsCollectionsCollectionIdPlotsPatchMutation();

  const [
    addLandCoverClass,
  ] = KanopApi.useCreateLandCoverClassesCollectionsCollectionIdLandCoverClassesPostMutation();

  const handleFeatureSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    if (!currentFeature || currentFeature.length < 1) return;

    // Check if formState has at least one required field filled
    if (formState?.canopyCover
     || formState?.canopyHeightMean
     || formState?.treeHeightMean
     || formState?.soilOrganicCarbon30cm
     || formState?.agbValue
     || formState?.landCoverClassId
     || inputValues) {
      try {
        let updatedFormState = { ...formState };

        // If a new land cover class is being added, handle that first
        if (inputValues && selectedColor) {
          const response = await addLandCoverClass({
            collectionId: collectionId as string,
            landCoverClasses: [{
              label: inputValues,
              color: selectedColor.color,
            }],
          }).unwrap();

          const newLandCoverClassId = response.classes[0].classId;

          updatedFormState = {
            ...updatedFormState,
            landCoverClassId: newLandCoverClassId,
          };

          setFormState(updatedFormState);

          setNewLandCover(false);
          refetchLandCover();
        }

        const newFeature = {
          ...currentFeature[0],
          properties: {
            ...currentFeature[0].properties,
            ...updatedFormState,
          },
        };

        // If plotId exists, update plot
        if (newFeature.properties.plotId) {
          await upDatePlot({
            collectionId: collectionId as string,
            featureCollectionPlotGroundTruthInput: {
              type: 'FeatureCollection',
              features: [newFeature] as unknown as PlotGroundTruth[],
            },
          }).unwrap();

          toast.success('Plot data successfully updated');
        } else {
        // Otherwise, upload as a new plot
          await uploadPlot({
            collectionId: collectionId as string,
            featureCollectionPlotGroundTruthInput: {
              type: 'FeatureCollection',
              features: [newFeature] as unknown as PlotGroundTruth[],
            },
          }).unwrap();

          toast.success('Plot data successfully added');
          draw?.deleteAll();
        }
        setFeature(newFeature);
        setOpen(false);
        setproperties(newFeature.properties);
        removeLayerAndSource(map as mapboxgl.Map);
        refetch(); // Fetch updated plot data
        setPlotData(plot as PlotsResponse);
      } catch (error: any) {
        const errorMessage = error?.response?.data?.detail || error?.message || 'An error occurred';
        toast.error(errorMessage);
      }
    } else {
      setIsError(true);
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // Set the form state to the properties of the current feature
  useEffect(() => {
    setFormState({
      plotReference: properties?.plotReference || '',
      observationDate: properties?.observationDate || '',
      treeHeightMean: properties?.treeHeightMean,
      canopyHeightMean: properties?.canopyHeightMean,
      canopyCover: properties?.canopyCover,
      agbValue: properties?.agbValue || null,
      landCoverClassId: properties?.landCoverClassId,
      soilOrganicCarbon30cm: properties?.soilOrganicCarbon30cm,
      comments: properties?.comments,
      collectionId,
    });
  }, [properties, collectionId]);

  // Create an array of form fields based on the formState object
  const formFields = Object.entries(formState).map(([key, value]) => {
    let placeholder = '';
    let type = '';
    switch (key) {
      case 'plotReference':
        placeholder = 'Plot reference *';
        type = 'text';
        break;
      case 'observationDate':
        placeholder = 'Observation date *';
        type = 'date';
        break;
      case 'treeHeightMean':
        placeholder = 'Tree height mean (m)';
        type = 'number';
        break;
      case 'canopyHeightMean':
        placeholder = 'Canopy height mean (m)';
        type = 'number';
        break;
      case 'canopyCover':
        placeholder = 'Canopy cover (%)';
        type = 'number';
        break;
      case 'agbValue':
        placeholder = 'Aboveground biomass (t / ha)';
        type = 'number';
        break;
      case 'landCoverClassId':
        placeholder = 'Land cover';
        // No input rendered for 'landCoverClassId'
        return null;
      case 'soilOrganicCarbon30cm':
        placeholder = 'Soil organic carbon stock 30 cm (tc / ha)';
        type = 'number';
        break;
      case 'comments':
        placeholder = 'Comment';
        type = 'text';
        break;
      default:
        placeholder = '';
    }
    return key !== 'landCoverClassId' && key !== 'color' && key !== 'collectionId' ? (
      <TextInput
        key={key}
        disabled={collectionLocked}
        name={key}
        value={value?.toString() as string}
        onChange={handleChange}
        placeholder={placeholder}
        sx={{ fontSize: '14px' }}
        type={type}
      />
    ) : null;
  });

  // Find the index where 'observationDate' is located
  const observationDateIndex = formFields.findIndex((field) => field?.props.name === 'observationDate');

  // Insert the LandCoverSelector component right after 'observationDate'
  if (observationDateIndex !== -1) {
    formFields.splice(
      observationDateIndex + 1,
      0, (
        <LandCoverSelector
          key="landCoverSelector"
          formState={formState}
          disabled={collectionLocked}
          setFormState={setFormState}
          landCover={landCover}
          refetch={refetchLandCover}
          landCoverOptions={landCoverOptions}
          setLandCoverOptions={setLandCoverOptions}
          newLandCover={newLandCover}
          setNewLandCover={setNewLandCover}
          inputValues={inputValues}
          setInputValues={setInputValues}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          ColorError={ColorError}
        />
      ),
    );
  }

  return (
    <Stack
      sx={{
        borderRadius: 'md',
        backgroundColor: darkMode ? '#021010' : '#FFFFFF',

      }}
      className="form-container"
    >
      <form onSubmit={handleFeatureSubmit}>
        <Typography level="body-sm" marginBottom={1} textAlign="center">Plot data</Typography>
        {formFields}
        <div className="btn-group">
          <div className="form-group">
            <Button
              type="button"
              color="neutral"
              className="cancel-btn"
              onClick={handleCancel}
              sx={{
                display: collectionLocked ? 'none' : 'inherit',
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="form-group">
            <Button
              type="submit"
              color="primary"
              className="submit-btn"
              loading={isUploading}
              sx={{
                display: collectionLocked ? 'none' : 'inherit',
              }}
            >
              Save
            </Button>
          </div>
        </div>
        {collectionLocked && (
        <Typography level="body-xs" textAlign="center">
          These fields are locked due to the collection status and cannot be edited.
        </Typography>
        )}
      </form>
      {isError && (
      <Typography level="body-sm" color="danger" textAlign="center">
        To create this plot, please ensure you have added at least one data point.
      </Typography>
      )}
    </Stack>
  );
};

export default PopupForm;
